import { apiCall } from './apiCall'
import { RequestBody, ResponseType } from './apiCall'

type ArrElement<ArrType> = ArrType extends (infer ElementType)[] ? ElementType : never

type NotificationResponse = ResponseType<'/notification', 'get'>

type NotificationItem = ArrElement<NotificationResponse['items']>

type PostNotificationBody = RequestBody<'/notification/0', 'post'>

function apiGetNotifications(pageNum?: number | undefined, perPageNum?: number | undefined) {
    return apiCall(`/notification`, 'get', { page: pageNum || 1, perPage: perPageNum || 1000 }, null)
}

function apiUpdateNotification(body: PostNotificationBody, id: number) {
    return apiCall(`/notification/${id}`, 'post', null, body)
}

function apiGetSingleNotification(id: number) {
    return apiCall(`/notification/${id}`, 'get', null, null)
}

function apiReadAllNotification() {
    return apiCall(`/notification/read/all`, 'post', null, null)
}

// function apiDeleteNotification(id: number) {
//     return apiCall(`/notification/${id}`, 'delete', null, null)
// }

export { apiGetNotifications, apiUpdateNotification, apiGetSingleNotification, apiReadAllNotification }

export type { NotificationResponse, PostNotificationBody, NotificationItem }
