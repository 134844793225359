import { apiCall } from './apiCall'
import { RequestBody, ResponseType } from './apiCall'
//REVIEW CRUQ
import { type Mediachannel, type Api } from './_apiTypes'

type ArrElement<ArrType> = ArrType extends (infer ElementType)[] ? ElementType : never

type MediaChannelResponse = ResponseType<'/mediachannel', 'get'>

type MediaChannelItem = ArrElement<MediaChannelResponse['items']>

type PostMediaChannelIdBody = RequestBody<'/mediachannel/0', 'post'>

type CreateMediaChannelBody = RequestBody<'/mediachannel', 'post'>

function apiGetMediaChannels() {
    return apiCall('/mediachannel', 'get', { page: 1, perPage: 1000 }, null)
}
function apiGetMediaChannelsById(id: number) {
    return apiCall(`/mediachannel/${id}`, 'get', null, null)
}
// function apiGetMediaChannel(id: number) {
//     return apiCall(`/mediachannel/${id}`, 'get', null, null)
// }
function apiCreateMediaChannel(body: CreateMediaChannelBody) {
    return apiCall(`/mediachannel`, 'post', null, body)
}

function apiUpdateMediaChannel(id: number, body: PostMediaChannelIdBody) {
    return apiCall(`/mediachannel/${id}`, 'post', null, body)
}

function apiDeleteMediaChannel(id: number) {
    return apiCall(`/mediachannel/${id}`, 'delete', null, null)
}

export { apiGetMediaChannels, apiUpdateMediaChannel, apiCreateMediaChannel, apiDeleteMediaChannel }
export type { MediaChannelResponse, MediaChannelItem, CreateMediaChannelBody }

//REVIEW CRUQ
const mediachannelApi = {
    get: apiGetMediaChannelsById,
    create: apiCreateMediaChannel,
    update: apiUpdateMediaChannel,
    delete: apiDeleteMediaChannel,
} as unknown as Api<Mediachannel>

export default mediachannelApi
