import { apiCall } from './apiCall'
import { RequestBody, ResponseType } from './apiCall'

import { type Calendar, type Api } from './_apiTypes'

type ArrElement<ArrType> = ArrType extends (infer ElementType)[] ? ElementType : never

type CalendarResponse = ResponseType<'/calendar', 'get'>
type CalendarItem = ArrElement<CalendarResponse['items']>

type PostCalendarIdBody = RequestBody<'/calendar/0', 'post'>

type CreateCalendarBody = RequestBody<'/calendar', 'post'>

function getCalendars() {
    return apiCall('/calendar', 'get', { page: 1, perPage: 1000 }, null)
}

function apiGetCalendarById(id: number) {
    return apiCall(`/calendar/${id}`, 'get', null, null)
}

function apiUpdateCalendar(id: number, body: PostCalendarIdBody) {
    return apiCall(`/calendar/${id}`, 'post', null, body)
}

function apiDeleteCalendar(id: number) {
    return apiCall(`/calendar/${id}`, 'delete', null, null)
}

function apiCreateCalendar(body: CreateCalendarBody) {
    return apiCall(`/calendar`, 'post', null, body)
}

export { getCalendars }
export type { CalendarResponse, CalendarItem }

//REVIEW CRUQ
const CalendarApi = {
    get: apiGetCalendarById,
    create: apiCreateCalendar,
    update: apiUpdateCalendar,
    delete: apiDeleteCalendar,
} as Api<Calendar>

export default CalendarApi
