import { useAuthStore } from './authStore'
import { useCalendarStore } from './calendarStore'
import { useOrganisationStore } from './organisationStore'
import { useCommunicationStore } from './communicationStore'
import { useTaskStore } from './taskStore'
import { type RefreshGroup } from '@/utilities/refreshProgress'

/*
    This code is used in TheLoadingScreen to initialize all stores it requires for the first refresh.
    The issue it resolves is that each store is responsible supplying the callbacks the refresher needs to call.
    If refresh is called before a store is initialized, its callbacks will not be executed on refresh and data goes missing.
    A resolution that solves this issue beyond this code is welcome. Preferably only used in TheLoadingScreen.
*/

type UnionToObject<U extends string> = {
    [K in U]: any
}

type RefreshObj = UnionToObject<RefreshGroup>

export default () => {
    const res: RefreshObj = {
        auth: useAuthStore(),
        org: useOrganisationStore(),
        cal: useCalendarStore(),
        task: useTaskStore(),
        comm: useCommunicationStore(),
    }
    return res
}
