import { apiCall } from './apiCall'
import { type Api, type Collaboration } from './_apiTypes'
import { RequestBody, ResponseType } from './apiCall'
import refreshProgress from '@/utilities/refreshProgress'

type ArrElement<ArrType> = ArrType extends (infer ElementType)[] ? ElementType : never
type OrganisationResponse = ResponseType<'/organisation', 'get'> & { sso?: boolean }
type PostOrganisationBody = RequestBody<'/organisation', 'post'>
type PostUpgradeBody = RequestBody<'/subscription/upgrade', 'post'>
type PostProlongBody = RequestBody<'/subscription/prolong', 'post'>
type DeleteOrganisationBody = RequestBody<'/organisation/delete', 'post'>
type SubscriptionResponse = ResponseType<'/subscription', 'get'>
type SubscriptionTypesResponse = ResponseType<'/subscriptionType', 'get'>
type SubscriptionTypeItem = ArrElement<SubscriptionTypesResponse['items']>
type ObligationResponse = ResponseType<'/obligation', 'get'>

function apiGetOrganisation() {
    return apiCall(`/organisation`, 'get', null, null)
}

function apiUpdateOrganisation(body: PostOrganisationBody) {
    return apiCall(`/organisation`, 'post', null, body)
}

function apiDeleteOrganisation(body: DeleteOrganisationBody) {
    return apiCall(`/organisation/delete`, 'post', null, body)
}

function apiGetCollaborations() {
    return apiCall(`/collaboration`, 'get', { perPage: 1000 }, null)
}

async function apiResetCollaboration(text: string) {
    try {
        const _res = await apiCall('/collaboration/reset/me', 'get', null, null)
        if (!_res || !_res.success) return
        refreshProgress.runRefresh(text)
    } catch (e) {
        throw e
    }
}

async function apiCollaborate(id: number, name?: string) {
    try {
        const _res = await apiCall(`/collaboration/start/${id}`, 'get', null, null)
        if (!_res || !_res.success) return
        await refreshProgress.runRefresh(name || '...')
        return _res
    } catch (e) {
        throw e
    }
}

function apiGetCollaboration(id: number) {
    return apiCall(`/collaboration/${id}`, 'get', null, null)
}

function apiUpdateCollaboration(id: number, body: any) {
    //console.log('apiUpdateCollaboration', id, body)
    return apiCall(`/collaboration/${id}`, 'post', null, body)
}

function apiGetSubscription() {
    return apiCall(`/subscription`, 'get', null, null)
}

function apiGetSubscriptionTypes() {
    return apiCall(`/subscriptionType`, 'get', {}, null)
}

function apiUpgradeSubscription(body: PostUpgradeBody) {
    return apiCall(`/subscription/upgrade`, 'post', null, body)
}

function apiProlongSubscription(body: PostProlongBody) {
    return apiCall(`/subscription/prolong`, 'post', null, body)
}

function apiGetObligation() {
    return apiCall(`/obligation`, 'get', {}, null)
}

// async function apiDeleteOrganisation() {
//   return await apiCall(`/mediachannel`, 'delete', null, null)
// }

export {
    apiGetOrganisation,
    apiUpdateOrganisation,
    apiDeleteOrganisation,
    apiGetCollaborations,
    apiResetCollaboration,
    apiCollaborate,
    apiGetCollaboration,
    apiUpdateCollaboration,
    apiGetSubscription,
    apiGetSubscriptionTypes,
    apiUpgradeSubscription,
    apiProlongSubscription,
    apiGetObligation,
}

export const collaborationApi = {
    get: apiGetCollaboration,
    create: () => {},
    update: apiUpdateCollaboration,
    delete: () => {},
} as unknown as Api<Collaboration>

export type {
    OrganisationResponse,
    PostOrganisationBody,
    DeleteOrganisationBody,
    SubscriptionResponse,
    SubscriptionTypesResponse,
    SubscriptionTypeItem,
    PostUpgradeBody,
    PostProlongBody,
    ObligationResponse,
}
