import { createVuetify } from 'vuetify'
// import * as labsComponents from 'vuetify/labs/components'
import { aliases, fa } from 'vuetify/iconsets/fa'

const observerConfig = {
    childList: true,
}

document.addEventListener('DOMContentLoaded', () => {
    // Select the style tag to observe
    const styleTag = document.querySelector('#vuetify-theme-stylesheet')
    if (!styleTag) return

    // Create a Mutation Observer
    const observer = new MutationObserver(styleUpdated)
    // Configure the observer to watch for changes to the content

    styleUpdated(
        [
            {
                type: 'childList',
                target: styleTag,
            },
        ],
        observer
    )
    // Start observing the style tag
    observer.observe(styleTag, observerConfig)
})

function removeImportant(text: string) {
    // Use a regular expression to find and replace !important; with an empty string
    return text.replace(/!important;/g, '')
}

function styleUpdated(mutationsList: MutationRecord[] | [{ type: string; target: Element }], observer: MutationObserver) {
    for (const mutation of mutationsList) {
        if (mutation.type === 'childList') {
            // The content of the style tag has changed
            observer.disconnect()
            // remove !important; styles
            const editedStyles = removeImportant(mutation.target.textContent || '')
            mutation.target.textContent = `@layer vuetify {
                ${editedStyles} }`
            observer.observe(mutation.target, observerConfig)
        }
    }
}

export default createVuetify({
    // components: {
    //     ...labsComponents,
    // },
    icons: {
        defaultSet: 'fa',
        aliases,
        sets: {
            fa,
        },
    },
})
