import { apiCall } from './apiCall'
import { RequestBody, ResponseType } from './apiCall'

import { type User, type Api } from './_apiTypes'

type ArrElement<ArrType> = ArrType extends (infer ElementType)[] ? ElementType : never

type UsersResponse = ResponseType<'/user', 'get'>
type UserItem = ArrElement<UsersResponse['items']> & { name?: string }

type PostUserIdBody = RequestBody<'/user/0', 'post'>

type CreateUserBody = RequestBody<'/user', 'post'>

type ActivateUserBody = RequestBody<'/user/password/set', 'post'>

type ActivateUserResponse = ResponseType<'/user/password/set/0', 'get'> & { is_sso?: boolean }

// add the optional property 'name' to the UserItem type

// type PostAudienceIdBody = RequestBody<'/audience/0', 'post'>

// type CreateAudienceBody = RequestBody<'/audience', 'post'>

function apiGetUsers() {
    return apiCall('/user', 'get', { page: 1, perPage: 1000 }, null)
}

function apiGetUserById(id: number) {
    return apiCall(`/user/${id}`, 'get', null, null)
}

function apiUpdateUser(id: number, body: PostUserIdBody) {
    return apiCall(`/user/${id}`, 'post', null, body)
}

function apiDeleteUser(id: number) {
    return apiCall(`/user/${id}`, 'delete', null, null)
}

function apiCreateUser(body: CreateUserBody) {
    return apiCall(`/user`, 'post', null, body)
}

function apiGetActivateUser(activationKey: string) {
    return apiCall(`/user/password/set/${activationKey}`, 'get', null, null)
}

function apiActivateUser(body: ActivateUserBody) {
    return apiCall(`/user/password/set`, 'post', null, body)
}

function apiResendActivation(id: number) {
    return apiCall(`/user/${id}/reactivate`, 'post', null, undefined)
}

export { apiGetUsers, apiGetActivateUser, apiActivateUser, apiResendActivation }
export type { UsersResponse, UserItem, ActivateUserBody, ActivateUserResponse }

const UserApi = {
    get: apiGetUserById,
    create: apiCreateUser,
    update: apiUpdateUser,
    delete: apiDeleteUser,
} as unknown as Api<User>

export default UserApi
