<script setup lang="ts">
    import { ref, watchEffect, onMounted } from 'vue'

    import handleMessages from '@/utilities/handleMessages'

    onMounted(() => {
        handleMessages.resetDevMessages()
    })
    // const messagesLength = computed(() => devMessages.value.length > 0)
    const showToast = ref(false)
    watchEffect(() => {
        if (handleMessages.devMessages.value.length > 0) {
            showToast.value = true
        } else {
            showToast.value = false
        }
    })
</script>

<template>
    <v-snackbar v-model="showToast" multi-line>
        <div v-for="message in handleMessages.devMessages.value" class="d-block">
            <b>{{ message.title }}</b
            ><template v-if="message.description"><br />{{ message.description }}</template>
        </div>
        <template v-slot:actions>
            <v-btn color="red" variant="text" @click="handleMessages.resetDevMessages()"> Close </v-btn>
        </template>
    </v-snackbar>
</template>
