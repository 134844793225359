import apiCall from './apiCall'
import type { ResponseType } from './apiCall'
import { PickRequired } from '@/utilities/_utilityTypes'

type ArrElement<ArrType> = ArrType extends (infer ElementType)[] ? ElementType : never
type RoleResponse = ResponseType<'/businessrole', 'get'>
type RoleItem = PickRequired<ArrElement<RoleResponse['items']>, 'id'>
type WebroleResponse = ResponseType<'/webrole', 'get'>
type WebroleItem = ArrElement<WebroleResponse['items']>

function apiGetBusinessrole() {
    return apiCall('/businessrole', 'get', null, null)
}
function apiGetWebrole() {
    return apiCall('/webrole', 'get', null, null)
}

export { apiGetBusinessrole, apiGetWebrole, type RoleItem, type WebroleItem }
