import { ref } from 'vue'
type Alertmode = 'success' | 'info' | 'warning' | 'error' | 'development'
const messages = ref<{ title: string; description?: string; mode?: Alertmode | undefined }[]>([])
const devMessages = ref<{ title: string; description?: string }[]>([])
// cretae a function to handle Messages
export default {
    messages,
    devMessages,
    devLog(msg: any) {
        if (!msg) return
        if (import.meta.env.VITE_ENVIRONMENT == 'dev') {
            console.log('[ CK Vue 3 msg: ]', msg)
        }
    },
    dev(msg: any, description?: string) {
        if (!msg) return
        if (import.meta.env.VITE_ENVIRONMENT == 'dev') {
            this.setMessage({ title: msg, description }, true)
        }
    },
    setError(msg?: string | null | undefined, description?: string | null | undefined) {
        if (!msg && !description) return
        this.setMessage({ title: msg, description, mode: 'error' })
    },
    setWarning(msg?: string | null | undefined, description?: string | null | undefined) {
        if (!msg && !description) return
        this.setMessage({ title: msg, description, mode: 'warning' })
    },
    setInfo(msg?: string | null | undefined, description?: string | null | undefined) {
        if (!msg && !description) return
        this.setMessage({ title: msg, description, mode: 'info' })
    },
    setSuccess(msg?: string | null | undefined, description?: string | null | undefined) {
        if (!msg && !description) return
        this.setMessage({ title: msg, description, mode: 'success' })
    },
    setMessage(theAlert: any, dev?: boolean) {
        if (!theAlert) return
        // check if thAlert is already in the messages array
        if (!dev) {
            if (
                messages.value.some((al) => {
                    return al.title === theAlert.title && al.description === theAlert.description
                }) == false
            ) {
                messages.value.push(theAlert)
            }
        }
        if (dev) {
            if (
                devMessages.value.some((al) => {
                    return al.title === theAlert.title && al.description === theAlert.description
                }) == false
            ) {
                devMessages.value.unshift(theAlert)
            }
        }
    },
    resetMessage(id: number) {
        messages.value.splice(id, 1)
    },
    resetMessages() {
        messages.value = []
    },
    resetDevMessages() {
        devMessages.value = []
    },
    getMessages() {
        return messages
    },
}
