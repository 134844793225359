import { apiCall } from './apiCall'
import { RequestBody, ResponseType } from './apiCall'

import { type Task, type Api } from './_apiTypes'

type ArrElement<ArrType> = ArrType extends (infer ElementType)[] ? ElementType : never

type TaskResponse = ResponseType<'/task', 'get'>
type TaskItem = ArrElement<TaskResponse['items']>

type PostTaskIdBody = RequestBody<'/task/0', 'post'>

type CreateTaskBody = RequestBody<'/task', 'post'>

// Get a list of multiple tasks
function apiGetTasks(pageNum?: number | undefined, perPageNum?: number | undefined) {
    return apiCall('/task', 'get', { page: pageNum || 1, perPage: perPageNum || 1000 }, null)
}

function apiGetTaskByCommunicationId(id: number) {
    return apiCall(`/communication/${id}/task`, 'get', { page: 1, perPage: 100 }, null)
}

function apiGetTaskById(id: number) {
    return apiCall(`/task/${id}`, 'get', null, null)
}

function apiUpdateTask(id: number, body: PostTaskIdBody) {
    return apiCall(`/task/${id}`, 'post', null, body)
}

function apiDeleteTask(id: number) {
    return apiCall(`/task/${id}`, 'delete', null, null)
}

function apiCreateTask(body: CreateTaskBody) {
    return apiCall(`/task`, 'post', null, body)
}

function apiGetRequests() {
    return apiCall('/request', 'get', { page: 1, perPage: 1000 }, null)
}

export { apiGetTasks, apiGetTaskById, apiGetTaskByCommunicationId, apiUpdateTask, apiCreateTask, apiDeleteTask, apiGetRequests }
export type { TaskResponse, TaskItem, CreateTaskBody }

//REVIEW CRUQ
const TaskApi = {
    get: apiGetTaskById,
    create: apiCreateTask,
    update: apiUpdateTask,
    delete: apiDeleteTask,
} as Api<Task>

export default TaskApi
