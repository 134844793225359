import { computed } from 'vue'
import { useAuthStore } from '@/stores/authStore'
import { storeToRefs } from 'pinia'
// plugins
import { productFruits } from 'product-fruits'
import Smartlook from 'smartlook-client'

const pfToken = import.meta.env.VITE_PRODUCTFRUIT || '' // product fruit token
const slToken = import.meta.env.VITE_SMARTLOOK || '' // smartlook token

const authStore = computed(() => (useAuthStore ? useAuthStore() : null))

export function initializeProductFruits() {
    if (!authStore.value) return
    const _authStore = authStore.value
    const { me, isLoggedIn, subscription } = storeToRefs(_authStore)
    if (!isLoggedIn || !(me.value && me.value.id)) return

    type Lang = keyof typeof lang
    const created = new Date(me.value.created_at ? me.value.created_at : '').toISOString()
    const lang = {
        'nl-nl': 'nl',
        'en-en': 'en',
        en: 'en',
        'de-de': 'de',
        'nl-rBE': 'nl',
        'fr-fr': 'fr',
    }
    const webrole = _authStore.webRoles.find((role) => role.id === me.value.webrole_id)?.name
    if (pfToken.length > 1) {
        const pfUser = {
            username: `${me.value.id}`,
            email: `${me.value.emailAddress}`,
            signUpAt: `${created}`,
            role: `${webrole}`,
            props: {
                subscriptiontype: `${subscription.value}`,
                company_ID: `${me.value.organisation_id}`,
            },
        }
        productFruits.init(pfToken, lang[me.value.languageCode as Lang] || 'nl', pfUser as any)
    }
    if (slToken.length > 1) {
        Smartlook.init(slToken, { region: 'eu' })
        Smartlook.identify(me.value.id, {
            Subscription: `${subscription.value}`,
            Company_ID: `${me.value.organisation_id}`,
            SignUp: `${created}`,
            Authorisation: `${webrole}`,
        })
    }

    console.log('ProductFruits and Smartlook initialized')
}
