import { ref } from 'vue'

import nl from './nl/webapp.json'
import en from './en/webapp.json'
import fr from './fr/webapp.json'
import de from './de/webapp.json'
import nl_BE from './nl-BE/webapp.json'

export const defaultLocale = 'nl'

export const locales = ref({
    en,
    nl,
    fr,
    de,
    ['nl_BE']: nl_BE,
})

export const localekeys = new Set(Object.keys(locales.value) as Array<keyof typeof locales.value>)

export type LocaleKey = keyof typeof locales.value
