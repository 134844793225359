import apiCall from './apiCall'
import { Api, Project } from './_apiTypes'
import type { RequestBody, RequestParams, ResponseType } from './apiCall'

type ArrElement<ArrType> = ArrType extends (infer ElementType)[] ? ElementType : never

type ProjectResponse = ResponseType<'/project', 'get'>

type CalendarResponse = ResponseType<'/calendar', 'get'>

type CalendarItem = ArrElement<CalendarResponse['items']>

type TypedValue<T extends string, U> = U & { __type: T }

type ProjectId = TypedValue<'project', Pick<ArrElement<ProjectResponse['items']>, 'id'>>

type ProjectItem = Omit<ArrElement<ProjectResponse['items']>, 'id'> & {
    id: ProjectId
}

type PostProjectIdBody = RequestBody<'/project/0', 'post'>

type CreateProjectBody = RequestBody<'/project', 'post'>

type ResetResponse = ResponseType<'/reset/project', 'get'>

function apiGetProjects(params?: RequestParams<'/project', 'get'>) {
    return apiCall(`/project`, 'get', params || {}, null)
}
function apiGetProjectById(id: number) {
    return apiCall(`/project/${id}`, 'get', null, null)
}
function apiUpdateProjectById(id: number, body: PostProjectIdBody) {
    return apiCall(`/project/${id}`, 'post', null, body)
}
function apiDeleteProjectById(id: number) {
    return apiCall(`/project/${id}`, 'delete', null, null)
}
function apiCreateProject(body: CreateProjectBody) {
    return apiCall(`/project`, 'post', null, body)
}
function apiResetTestProject() {
    return apiCall(`/reset/project`, 'get', null, null)
}

export { apiGetProjects, apiGetProjectById, apiUpdateProjectById, apiCreateProject, apiDeleteProjectById, apiResetTestProject }
export type { ProjectResponse, ProjectItem, CalendarResponse, CalendarItem, PostProjectIdBody, CreateProjectBody, ResetResponse }

/*
Default export with default keys of [get, update, create, delete]
*/
const projectApi = {
    get: apiGetProjectById,
    update: apiUpdateProjectById,
    create: apiCreateProject,
    delete: apiDeleteProjectById,
} as Api<Project>

export default projectApi
