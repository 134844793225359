import { apiCall } from './apiCall'
import { RequestBody, ResponseType } from './apiCall'
//REVIEW CRUQ
import { type Label, type Api } from './_apiTypes'

type ArrElement<ArrType> = ArrType extends (infer ElementType)[] ? ElementType : never

type LabelResponse = ResponseType<'/label', 'get'>

type LabelItem = ArrElement<LabelResponse['items']>

type PostLabelIdBody = RequestBody<'/label/0', 'post'>

type CreateLabelBody = RequestBody<'/label', 'post'>

function apiGetLabels() {
    return apiCall('/label', 'get', { page: 1, perPage: 1000 }, null)
}

function apiGetLabelById(id: number) {
    return apiCall(`/label/${id}`, 'get', null, null)
}

function apiUpdateLabel(id: number, body: PostLabelIdBody) {
    return apiCall(`/label/${id}`, 'post', null, body)
}

function apiDeleteLabel(id: number) {
    return apiCall(`/label/${id}`, 'delete', null, null)
}

function apiCreateLabel(body: CreateLabelBody) {
    return apiCall(`/label`, 'post', null, body)
}

export { apiGetLabels, apiUpdateLabel, apiCreateLabel, apiDeleteLabel }
export type { LabelResponse, LabelItem, CreateLabelBody }

//REVIEW CRUQ
const LabelApi = {
    get: apiGetLabelById,
    create: apiCreateLabel,
    update: apiUpdateLabel,
    delete: apiDeleteLabel,
} as Api<Label>

export default LabelApi
