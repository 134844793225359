import { apiCall } from './apiCall'
import { RequestBody, ResponseType } from './apiCall'
import { type Holiday, type Api } from './_apiTypes'

type ArrElement<ArrType> = ArrType extends (infer ElementType)[] ? ElementType : never

type HolidayResponse = ResponseType<'/holiday', 'get'>

type HolidayItem = ArrElement<HolidayResponse['items']>

type CreateHolidayBody = RequestBody<'/holiday', 'post'>

type UpdateHolidayBody = RequestBody<'/holiday/0', 'post'>

// type HolidayDeleteBody = RequestBody<'/holiday/0', 'delete'>

// Get a list of multiple holidays
async function apiGetHolidays() {
    return await apiCall('/holiday', 'get', {}, null)
}

// Get a single holiday
function apiGetHoliday(id: number) {
    return apiCall(`/holiday/${id}`, 'get', null, null)
}

// Update a single holiday
function apiUpdateHoliday(id: number, body: UpdateHolidayBody) {
    return apiCall(`/holiday/${id}`, 'post', null, body)
}

// Create a new holiday
function apiCreateHoliday(body: Omit<CreateHolidayBody, 'id'>) {
    return apiCall(`/holiday`, 'post', null, body)
}

// Delete a single holiday
function apiDeleteHoliday(id: number) {
    return apiCall(`/holiday/${id}`, 'delete', null, null)
}

export { apiGetHolidays, apiGetHoliday, apiCreateHoliday, apiUpdateHoliday, apiDeleteHoliday }
export type { HolidayResponse, HolidayItem, CreateHolidayBody, UpdateHolidayBody }

const HolidayApi = {
    get: apiGetHoliday,
    create: apiCreateHoliday,
    update: apiUpdateHoliday,
    delete: apiDeleteHoliday,
} as Api<Holiday>

export default HolidayApi
