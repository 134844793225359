import { ref } from 'vue'

import { useI18n } from 'vue-i18n'

const globalt = ref()

export function setGlobalt() {
    const { t } = useI18n()
    globalt.value = t
}

export function useGlobalt() {
    return { globalt }
}
