import { defineStore } from 'pinia'
import { ref } from 'vue'

import { apiGetNotifications, apiUpdateNotification, apiReadAllNotification, type NotificationItem, type PostNotificationBody } from '@/api/notification'

export const useNotificationStore = defineStore('notification', () => {
    const notifications = ref<NotificationItem[]>()

    function refreshStore() {
        getNotifications()
    }

    async function getNotifications() {
        const response = await apiGetNotifications()
        if (response) {
            notifications.value = response.items
        }
    }

    async function updateNotification(body: PostNotificationBody, id: number) {
        try {
            const notificationResponse = await apiUpdateNotification(body, id)
            if (notificationResponse) {
                if (!notifications.value) return
                const index = notifications.value.findIndex((d) => d.id === id)
                notifications.value[index] = notificationResponse
            }
        } catch (error: any) {
            console.error('Notification read all error')
        }
    }

    async function allReadNotification() {
        try {
            const notificationResponse = await apiReadAllNotification()
            if (notificationResponse) {
                // update the read date of all notifications
                notifications.value?.forEach((no) => {
                    no.readAt = new Date().valueOf()
                    no.readNotification = true
                })
            }
        } catch (error: any) {
            console.error('Notification read all error')
        }
    }

    // async function deleteNotification(id: number) {
    //     try {
    //         const notificationResponse = await apiDeleteNotification(id)
    //         if (notificationResponse) {
    //             if (!notifications.value) return
    //             notifications.value = notifications.value.filter(
    //                 (notification) => {
    //                     return notification.id !== id
    //                 }
    //             )
    //         }
    //     } catch (error: any) {
    //         handleMessages.setError('Er is iets mis gegaan')
    //     }
    // }

    return {
        notifications,
        getNotifications,
        updateNotification,
        allReadNotification,
        refreshStore,
        // deleteNotification
    }
})
