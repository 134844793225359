<script setup lang="ts">
    import { onMounted } from 'vue'
    import { ref, computed, inject, nextTick } from 'vue'

    import { useAuthStore } from '@stores/authStore'
    import { storeToRefs } from 'pinia'
    const authStore = useAuthStore()
    const { language } = storeToRefs(authStore)

    const languageOptions = ref<any>([])

    onMounted(() => {
        setLanguageOptions()
    })

    function setLanguageOptions() {
        if (!language.value) return []
        if (!Array.isArray(language.value)) return []
        languageOptions.value = language.value.map((lang: any) => {
            const transKey = `languages.${lang.localizedName.toLowerCase()}`
            const languageName = t(transKey)
            const _label = transKey !== languageName ? languageName : lang.localizedName
            const path = `/flags/${lang.code.toLowerCase()}.svg`
            return { value: lang.code, label: _label, flag: path }
        })
    }

    import { useLocalStorage } from '@vueuse/core'

    import { useI18n } from 'vue-i18n'
    const { t } = useI18n()

    const localValue = localStorage.getItem('locale')
    const selectedLanguage = ref(localValue || 'nl')

    async function switchLanguage(languageCode: any) {
        selectedLanguage.value = languageCode
        await nextTick()
        setLanguageOptions()
    }

    const selectedLanguageFlag = computed(() => {
        const selected = languageOptions.value.find((lang: any) => lang.value === selectedLanguage.value)
        return selected ? selected.flag : ''
    })

    useI18n({ useScope: 'global' })
    inject<{ locale: string }>(Symbol.for('FormKitConfig'))

    useLocalStorage('locale', ref(selectedLanguage))
</script>

<template>
    <v-menu :close-on-content-click="true" location="end">
        <template v-slot:activator="{ props }">
            <v-list-item v-bind="props" class="current-language">
                <div class="flag-container">
                    <img :src="selectedLanguageFlag" class="flag" />
                </div>
                <v-tooltip activator="parent" theme="light" location="end">{{ t('user.profileView.language') }}</v-tooltip>
            </v-list-item>
        </template>
        <v-card min-width="300">
            <v-list>
                <template v-for="item in languageOptions">
                    <v-list-item :value="item" color="primary" @click="switchLanguage(item.value)">
                        <template v-slot:prepend>
                            <div class="flag-container">
                                <img :src="item.flag" class="flag" />
                            </div>
                        </template>
                        <v-list-item-title v-text="item.label"></v-list-item-title>
                    </v-list-item>
                </template>
            </v-list>
        </v-card>
    </v-menu>
</template>
<style lang="scss" scoped>
    .current-language {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    :deep(.current-language .v-list-item__content) {
        overflow: visible !important;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        box-shadow:
            rgba(255, 255, 255, 0.2) 0px 1px 3px 0px,
            rgba(255, 255, 255, 0.15) 0px 0px 0px 1px;
    }

    .flag-container {
        display: flex;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        overflow: hidden;
        box-shadow:
            rgba(255, 255, 255, 0.2) 0px 1px 3px 0px,
            rgba(255, 255, 255, 0.15) 0px 0px 0px 1px;
        margin-right: 10px;
    }

    .flag {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
</style>
