import { apiCall } from './apiCall'
import { RequestBody, ResponseType } from './apiCall'
//REVIEW CRUQ
import { type Workflow, type Api, type WorkflowTask } from './_apiTypes'

type ArrElement<ArrType> = ArrType extends (infer ElementType)[] ? ElementType : never

type workflowResponse = ResponseType<'/workflow', 'get'>

type workflowItem = ArrElement<workflowResponse['items']>

type UpdateWorkflowIdBody = RequestBody<'/workflow/0', 'post'>

type CreateworkflowBody = RequestBody<'/workflow', 'post'>

type CreateworkflowTaskBody = RequestBody<'/workflowtask', 'post'>

type UpdateworkflowTaskBody = RequestBody<'/workflowtask/0', 'post'>

// WORKFLOW
function apiGetWorkflows() {
    return apiCall('/workflow', 'get', { page: 1, perPage: 1000 }, null)
}

function apiGetWorkflowById(id: number) {
    return apiCall(`/workflow/${id}`, 'get', null, null)
}

function apiUpdateWorkflow(id: number, body: UpdateWorkflowIdBody) {
    return apiCall(`/workflow/${id}`, 'post', null, body)
}

function apiDeleteWorkflow(id: number) {
    return apiCall(`/workflow/${id}`, 'delete', null, null)
}

function apiCreateWorkflow(body: CreateworkflowBody) {
    return apiCall(`/workflow`, 'post', null, body)
}

// WORKFLOW TASK
function apiGetWorkflowTaskById(id: number) {
    return apiCall(`/workflowtask/${id}`, 'get', null, null)
}

function apiCreateWorkflowTask(body: CreateworkflowTaskBody) {
    return apiCall('/workflowtask', 'post', null, body)
}

function apiUpdateWorkflowTask(id: number, body: UpdateworkflowTaskBody) {
    return apiCall(`/workflowtask/${id}`, 'post', null, body)
}

function apiDeleteWorkflowTask(id: number, delete_existing: boolean | undefined | null = false) {
    let doDelete = delete_existing ? true : false
    return apiCall(`/workflowtask/${id}/${doDelete}`, 'delete', null, null)
}

export { apiGetWorkflows, apiUpdateWorkflow, apiCreateWorkflow, apiDeleteWorkflow, apiGetWorkflowTaskById, apiCreateWorkflowTask, apiUpdateWorkflowTask, apiDeleteWorkflowTask }
export type { workflowResponse, workflowItem, CreateworkflowBody, CreateworkflowTaskBody, UpdateworkflowTaskBody }

//REVIEW CRUQ
export const workflowApi = {
    get: apiGetWorkflowById,
    create: apiCreateWorkflow,
    update: apiUpdateWorkflow,
    delete: apiDeleteWorkflow,
} as Api<Workflow>

export const workflowTaskApi = {
    get: apiGetWorkflowTaskById,
    create: apiCreateWorkflowTask,
    update: apiUpdateWorkflowTask,
    delete: apiDeleteWorkflowTask,
} as Api<WorkflowTask>
