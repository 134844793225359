import { apiCall } from './apiCall'
import { RequestBody, ResponseType } from './apiCall'

type ArrElement<ArrType> = ArrType extends (infer ElementType)[] ? ElementType : never

type CommunicationstatusResponse = ResponseType<'/communicationstatus', 'get'>

type CommunicationstatusItem = ArrElement<CommunicationstatusResponse['items']>

type PostCommunicationstatusIdBody = RequestBody<'/communicationstatus/0', 'post'>

type CreateCommunicationstatusBody = RequestBody<'/communicationstatus', 'post'>

function apiGetCommunicationstatus() {
    return apiCall('/communicationstatus', 'get', { page: 1, perPage: 1000 }, null)
}

function apiUpdateCommunicationstatusById(id: number, body: PostCommunicationstatusIdBody) {
    return apiCall(`/communicationstatus/${id}`, 'post', null, body)
}

function apiDeleteCommunicationstatusById(id: number) {
    return apiCall(`/communicationstatus/${id}`, 'delete', null, null)
}

function apiCreateCommunicationstatus(body: CreateCommunicationstatusBody) {
    return apiCall(`/communicationstatus`, 'post', null, body)
}

export { apiGetCommunicationstatus, apiUpdateCommunicationstatusById, apiCreateCommunicationstatus, apiDeleteCommunicationstatusById }
export type { CommunicationstatusResponse, CommunicationstatusItem, CreateCommunicationstatusBody }
